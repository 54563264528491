.containerFrontPage{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    cursor: none;
}

.logoFP{
    width: 100px;
    height: 78.75px;    
}

#logo1{
    width: 100%;
}
.mouse{
    position:fixed;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    transform-origin: center;
    transition: transform 0.5s ease;
}
.logoBack{
    position: fixed;
    width: 130px;
    /* -webkit-clip-path: circle(10px at 0 0);
    clip-path: circle(10px at 0 0); */


}