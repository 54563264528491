:root {
  --Projects_lr_padding: 1.5rem;
  --Projects_tb_padding: 0.8rem;
  --navbar_height: 3rem;
}

body::-webkit-scrollbar {
  display: none;
}

/* body {
 background-color: black;
} */

.Projects * {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  overflow-wrap: break-word;
  background-color: black;
}

.Projects {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: white;
  background-color: black;
}

.Projects h1 {
  font-size: 2.6em;
  font-weight: inherit;
}

.cover {
  position: fixed;
  top: 0;
  width: 100%;
}

.cover img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.info > *,
.gallery h1 {
  padding: var(--Projects_lr_padding);
  padding-top: var(--Projects_tb_padding);
  padding-bottom: 0;
}

.info {
  /* height: calc(100vh - var(--navbar_height)); */
  min-height: 4rem;
}

.info h1 {
  flex: 0 1 auto;
  top: 0;
  width: 100%;
}
.autors {
  padding-top: 0;
  font-size: 13px;
  font-weight: 300;
}
.autors div {
  padding: 0;
}

.description {
  flex: 1 1 auto;
  font-size: 0.95em;
  line-height: 1.6;
}

.gallery {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - var(--navbar_height) - 4rem);
}

.slideshow {
  flex: 1;
  width: 100%;
  padding-top: var(--Projects_padding);
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  display: flex;
}

.slideshow::-webkit-scrollbar {
  display: none;
}

.slideshow-img {
  scroll-snap-align: center;
  flex: 1 0 100%;
  height: 80%;
  position: relative;
}

.slideshow-img .cloudinary {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img-info {
  flex: 1;
}
.img-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}
.img-caption {
  font-size: 12px;
  font-weight: 100;
}
.img-caption-cover {
  bottom: 0;
  height: 3%;
  width: 100%;
  position: absolute;
  background-color: transparent;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.8),
    black
  );
  pointer-events: none;
}

.navbar {
  display: flex;
  position: sticky;
  bottom: 0;
  width: 100%;
}

.navbar:hover {
  cursor: pointer;
}

.navbar * {
  flex: 1;
  height: var(--navbar_height);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  font-weight: bold;
  text-decoration: none;
}

.title{
  width: 100%;
  height: 4rem;
  overflow: hidden;
  z-index: 100;
}
.text-title{
  white-space: nowrap;
  width: max-content;
  font-size: 1rem;
  transform-origin: top left;
}