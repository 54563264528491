@font-face {
    font-family: 'Neue Haash Grotesk Display Pro' ;
    src: url('/public/fontRefe/NeueHaasDisplay-Roman.ttf');
}

* {
    font-family: 'Neue Haash Grotesk Display Pro';
}


.projectsView{
    display: flex;
    flex-direction: column;
    background-color:  #fff6dc;
    min-height: 100vh;
    cursor: none;
}

.projectsView *{
    cursor: none;
}

.header{
    font-family: 'Neue Haash Grotesk Display Pro';
    background-color: #fff6dc;
    width: calc(100% - 2rem);
    height: 4.5rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
}

.footer{
    height: 3rem;
    width: 100%;
    background-color:#fff6dc;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button{
    font-family: 'Neue Haash Grotesk Display Pro';
    display: flex;
    color: black;
    padding: 1rem 0rem;
    padding-left:1rem;
    font-weight: bold;
    z-index: 100;
}

.button:active{
    scale: 0.9;
}

.buttonContainer{
    position:fixed;
    height: 4rem;
    top:0;
    right: 1rem;
    display: flex;
    align-items: center;
}

.container_projects{
    display: grid;
    min-height: 100vh;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: dense;
    gap: 3vh;
    margin: 5rem 2rem 4rem 2rem;
}

.project_double{
    grid-row: auto / span 2;
    height: 70vh;
}

.project{
    grid-row: auto;
    height: 32vh;
}

.title{
    height:5%;
    font-size: large;
    font-weight: 300;
}

@media (max-width: 900px) {
    .container_projects {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 600px) {
    .container_projects {
        grid-template-columns: 1fr;
    }
    .mouse{
        display: none;
    }
    .projectsView *{
        cursor:auto ;
    }
}

#logoProjectsView {
    position: fixed;
    top: 0.7rem;
    left: 0.7rem;
    height: 3rem;
    width: 5rem;
}

.mouse{
    pointer-events: none;
    /* position:absolute; */
    position: fixed;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #e83c30;
    overflow-x: hidden;
    transform-origin: center;
    transition: transform 0.5s ease;
    mix-blend-mode: darken;
}

.logoBack{
    position: fixed;
    top:calc(0.7rem - 15px);
    left:calc(0.7rem - 15px);
}