.container {
    background-color: black;
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.side{
    background-color: rgb(0, 0, 0);
    flex: 1;
    display: flex;
    flex-direction: column;
}

.side div{
    border-bottom: solid 1px white;
    border-top: solid 1px white;
    border-right: solid 2px white;
    display: flex;
    justify-content: center;

    padding: 10px 0 10px 0;
    color: grey;
}

.side::before{
    content:'';
    height: 70px;
    width: calc(100% - 2px);
    border-right: solid 2px white ;
    border-bottom: solid 1px white;
}
.side::after{
    content: '';
    flex: 1;
    background-color:rgb(0, 0, 0);
    border-right: solid 2px white;
    border-top: solid 1px white; 
}

.side div:hover{
    color: white;
    cursor: pointer;
}

.main{
    background-color: rgb(0, 0, 0);
    flex: 6;
    padding: 30px;
    overflow-y: scroll;
    
}

.projectLinkContainer{
    width: 100%;
    min-height: 50px;
    justify-content: space-between;
    align-content: center;
    border-top: solid 1px white;
    border-bottom: solid 1px white;
    margin-top: 2px;
}
.projectLinkContainer:hover{
    cursor: pointer;
    background-color: rgba(211, 211, 211, 0.337);
}

span {
    font-size:large;
}

.fixed{
    position:fixed;
}
button{
    margin: 2px;
    font-size: 1.1rem;
}
button[type = button]{
    background-color: rgb(0, 0, 0);
    color: lightcoral;
    border-radius: 5px;
    border: solid 1px darkred ;
}
button[type = button].reset{
    color:white;
    border: 0px;
    text-decoration: underline;
}
button[type = submit]{
    background-color: black;
    color: white;
    border-radius: 5px;
    border: solid 1px white;
}
button:hover{
    cursor: pointer;
}
.row{
    display: flex;
    flex-direction: row;
}
.column{
    display: flex;
    flex-direction: column;
}
textarea{
    box-sizing: border-box;
    background-color: black;
    border: solid 1px white;
    border-radius: 15px;
    height: 20em;
    color: white;
    padding: 1em;
    width: 100%;
}

label{
    font-size: 1.5em;
    margin: 0.7em 0 0.7em 0;
}

select, input[type=text], input[type=password]{
    box-sizing: border-box;
    height: 1.5em;
    background-color: black;
    color: white;
    border: solid 1px white;
    border-radius: 7px;
    padding-left: 1em;
    padding-right: 1em;
}
select{
    margin-left: 10px;
}
input[type=text]{
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
}

input[type = file]{
    margin-left: 10px;
}

.imageList > div:first-child{
    display: flex;
    justify-content: center;
    font-size: 2.5em;
    font-weight: 50;
    margin-top: 40px;
    margin-bottom: 30px;
    text-decoration: underline;
}

.imageList textarea{
    height: 10em;
}
.imageList label{
    font-size: medium;
    width: 10%;
    max-width: 5rem;
}
#new-project{
    background-color: black;
    color: white;
    height: 2em;
    border: solid 1px white;
    border-radius: 5px;
}

.confirmation{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
}

.confirmation > div:first-child{
    border: solid 1px white;
    border-radius: 5px;
    background-color: black;
    color: white;
    width: 30%;
    padding: 1em;
}
.confirmation button{
    margin: 1em;
}

.authors > div{
    cursor: pointer;
}

#login{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    top:0;
    left:0;
}
#login h1{
    font-size: 4rem;
    margin-top: 0;
}
#login input[type=text], input[type=password]{
    width:17rem;
    margin-bottom: 1em;
}
.imageList{
    display: flex;
    flex-direction: column;
    border-top: solid 1px grey;
    margin-top: 30px;
}

.dragPhoto{
    width: 100%;
    max-width: 900px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 1px white;
    border-radius: 10px;
    margin: 20px 0 40px 0;
    cursor: pointer;
    align-self: center;
}
button.deleteIcon, button.selectIcon, button.visibilityIcon{
    border-radius: 50%;
    border: solid 1px white;
    background-color: white;
    color: black;
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: relative;
    padding: 0;
}
button.deleteIcon{
    top:-95px;
    left:10px;
}
button.visibilityIcon, button.selectIcon{
    border-radius: 50%;
    background-color: black;
}
button.visibilityIcon{
    border: 0;
}
button.deleteCard{
    border:0;
    border-bottom: solid 1px white;
    margin: 0;
    border-radius: 0%;
    font-size: 0.9em;
}
button.infoCard{
    margin: 0;
    border: 0;
    color: white;
    font-size: 0.9em;
}


.imageCard{
    display: flex;
    flex-direction: column;
    width: 100px;
    border: solid 1px white;
    border-radius: 5px;
    margin:5px;
}

.imageDetail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    top:0;
    left:0;
}
.imageDetail form{
    display: flex;
    background-color: black;
    flex: 1;
    border: solid 1px white;
    border-radius: 7px;
    margin:0 10px;
}
.formImageDetail{
    padding: 5px 3%;
    display: flex;
    flex-direction: column;
}
.formImageDetail input, textarea{
    width: 100%;
}
.formImageDetail textarea{
    height: 7em;
}
.backButton{
    align-self: flex-end;
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    top:-30px;
}