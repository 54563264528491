@font-face {
    font-family: 'Neue Haash Grotesk Display Pro' ;
    src: url('/public/fontRefe/NeueHaasDisplay-Roman.ttf');
}

.header{
    background-color: #fff6dc;
    height: 4.3rem;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.containerProjects{
    display: flex;
    flex-direction: column;
    width: calc(100% - 15vw);    
}


.containerProjects a{
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: space-between;    
}

#footer{
    width: 100%;
    height: 18px;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left:0;
    display: flex;
    justify-content: center;
    margin: 0;
    margin-top: 30px;
    /* justify-content: center; */
    /* color:#c0c0c0;
    background-color: transparent;
    height: 20px;
    margin-left: 10px; */
}

.container {
    min-height: 100vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image:linear-gradient(#fff6dc, #fff6dc);
    background-clip: border-box;
    justify-content: space-between;
    padding: 2rem 1rem;
    padding-top: 90px;
    cursor: none;
    position: relative;    
}

.intro{
    margin-bottom: 20px;
    height: 100%;
    font-size: 3.5rem;
    font-weight: 500;
    text-align: center;
}

.container > * {
    cursor: none;
    margin-bottom: 30px;
}


.logoBack {
    position: fixed;
    top: calc(0.7rem - 15px);
    left: calc(0.7rem - 15px);
}

#logoBioAndInfo {
    position: fixed;
    top: 0.7rem;
    left: 0.7rem;
    height: 3rem;
    width: 5rem;
}
.mouse{
    pointer-events: none;
    /* position:absolute; */
    position: fixed;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #e83c30;
    overflow-x: hidden;
    transform-origin: center;
    transition: transform 0.5s ease;
    mix-blend-mode: darken;
}

.section{
    height: 20%;
    font-size: xx-large;
    font-family: 'Neue Haash Grotesk Display Pro';
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile_container, .authorsContacts{
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.profile_container > div, .authorsContacts > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.profile_container  p{
    font-size: large;
    font-family: 'Neue Haash Grotesk Display Pro';
    font-weight: 200;
}

.profile_container img p {
    transition: transform .1s;
}

.profile_container > div:hover img {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
    transform: scale(1.05);    
}
.profile_container > div:hover p{
    color: #e83c30;
    transform: scale(1.05);
}

.authorsContacts button{
    border: 0;
    background-color: transparent;
    font-size: x-large;
    font-weight: 400;
    transition: transform 2.;
}
.authorsContacts button:hover{
    color: #e83c30;
    transform: scale(1.1);
    cursor: none;
}

.profile_image, .contactsIcon, .instagramIcon{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: solid 1px red;
    object-fit: cover;
    object-position: 0px 0px; 
}
.contactsIcon{
    width: 8rem;
    height: 8rem;

}
.instagramIcon{
    border-radius: 23%;
    height: 3rem;
    width: 3rem;
    margin: 30px 10px;
}
.instagramLink{
    color: #e83c30;
    font-size: x-large;
    position: relative;
    top: -8px;
}
.author, .container h1{
    align-self: flex-start;
    padding: 0;
    position: fixed;
    top: 0;
    left: 6.5rem;
    margin-top: 1.7rem;
    font-size: 2rem;
    font-weight: 300;
    z-index: 100;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 2.3rem;
}
.description{
    width: 66%;
    margin: 30px 0px
}

.container iframe {
    border-radius: 10px;
    border: solid 1px red;
    margin: 10px 0;
    filter: sepia(0.2);
    
    /* pointer-events: none; */

}

.container a:link {
    color: black;
}

.container a:active {
    color: black;
}
.container a:visited {
    color: black;
}
.container a:hover {
    transform: scale(1.1);
    color: #e83c30;
    cursor: none;
}

@media (max-width: 800px) {
    .profile_container, .authorsContacts  {
        flex-direction: column;
    }

    .container > * {
        margin-bottom: 50px;
    }
}

