.ProjectAlt {
  /* background-color: black;
  color: white;
  font-family: Inter, sans-serif; */
  width: 100%;
  height: 100vh;
  display: flex;
  scroll-snap-align: center;
}

.ProjectAlt * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  position: relative;
  gap: 22px;
  padding: 4rem 0 5.5rem 0;
}

.aside-info-title{  
  color: white;
  width: 7rem;
  height: 100vh;
  display: none;
  flex-direction: column;
  justify-content: end;
  position: absolute;
  top:0;
  left: -4rem;
  padding-bottom: 8rem;
  pointer-events: none;
  z-index: 100;
  transition: left ease-in-out 0.3s, color ease-in-out 0.3s;
}
.aside-title-container-fixed-height{
  height: calc(100% - 15rem);
}
.aside-title-container-var-size{
  width: 100%;
  transform-origin: top left;
}


.aside-title{
  width: 100%;
  display: flex;
  align-items: flex-end;
  vertical-align: text-top;
  font-weight: 400;
  font-size: 2.5rem;
  writing-mode: vertical-lr;
  align-self: flex-start;
  rotate: 180deg;
  pointer-events: all;
}
.aside-info-title div p::first-letter{
  color: #e83c30;
}
.aside-info-title span{
  font-weight: 400;
  font-size: 1.8rem;
  align-self: flex-start;
  pointer-events: all;
  padding-left: 10px;
  padding-bottom: 10px;
  color: #e83c30;
  display: flex;
}
.aside-info-title:hover{
    color: #e83c30;
    left: -3.5rem;
    
}


.info {
  flex: 0 1 320px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 1rem;
}
.info::after{
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  height: 5rem;
  width: 100%;
  bottom: 0;
  position: absolute;
  background: linear-gradient(to top, rgba(0,0,0,1) 42%, rgba(255,255,255,0) 100%, rgba(255,255,255,0) 100%);

}
.titolo{
  width: 100%;
  height: 3.5rem;
}
.text-title {
  white-space: nowrap;
  width: max-content;
  font-size: 1rem;
  transform-origin: top left;
  font-weight: 500;
  min-width: 1px;
}

.autors{
  font-size: 13px;
  font-weight: 300;
}
.caption{
  display: flex;
  font-size: 15px;
  font-weight: 200;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5rem;
  width: 100%;
  gap: 22px;

}

.info-text {
  font-size: 0.9rem;
  line-height: 1.6em;
  font-weight: 400;
  
}

.description-container {
  position: relative;
  width: 100%;
  flex: 1;
  margin-top: 1rem;
  overflow-y: auto;
}

.description {
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
}

.description::-webkit-scrollbar {
  display: none;
}

.img-caption {
  width: 320px;
}

.img-caption-number {
  height: 1.5rem;
  font-weight: 500;
  position: relative;
}

.img-caption-text {
  width: 100%;
}

.img-container {
  flex: 1;
  height: 100%;
  position: relative;
  pointer-events: none;
}
.img-container * {
  pointer-events: all;
}

.handle-container-row-cursor {
  width: 100%;
  height: 100%;
  position: relative;
  top: -100%;
  left: 0;
  display: flex;
}

.handle-container-row-cursor :nth-child(1) {
  flex: 2;
}
.handle-container-row-cursor :nth-child(2) {
  flex: 1;
}
.handle-container-row-cursor :nth-child(3) {
  flex: 2;
}

.img-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

.img {
  display: block;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
}

/* .aside {
  display: flex;
  height: 100%;
  padding: 22px;
}

.aside-left {
  writing-mode: vertical-lr;
  transform: scale(-1);
}

.aside-right {
  writing-mode: vertical-lr;
}

.aside .space {
  color: white;
  flex: 1;
} */

@media (width >= 992px) {
  .ProjectAlt {
    padding: 0 3.7rem 0 0.7rem;
    width: calc(100% - 3.7rem);
    }
  .aside-info-title{
    left: 0rem;
  }
  .aside-info-title:hover{
    left: 0.5rem;
  }
}

@media (width >= 1300px) {
  .ProjectAlt {
    padding: 0 3.7rem 0 4.7rem;
    width: calc(100% - 9.4rem);
  }
  .aside-info-title{
    left: -4rem;
  }
  .aside-info-title:hover{
    left: -3.5rem;
  }
}

@media (width >= 1660px) {
  .ProjectAlt {
    padding: 0 3.7rem 0 4.7rem;
    width: calc(100% - 9.4rem);
  }
  .aside-info-title{
    left: -4rem;
  }
  .aside-info-title:hover{
    left: -3.5rem;
  }
}
