@font-face {
    font-family: 'Neue Haash Grotesk Display Pro' ;
    src: url('/public/fontRefe/NeueHaasDisplay-Roman.ttf');
}

#background{
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    object-fit: cover;
    position: absolute;
    top: -12;
    left: -6;
}

#cover_background{
    width: 150%;
    height: 150%;    
    object-fit: cover; 
    position: absolute;
    top:-25%;
    left:-25%;
}

#textBackground{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 150%;
    height: 150%;
    position: absolute;
    top:-25%;
    left:-25%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    text-align: left;
    font-family: 'Neue Haash Grotesk Display Pro';
    font-weight: bold;
    font-size: 4rem;
}

#textBackground > div:nth-child(1){
    margin-top: 62vh;
}


#home_footer{
    background-color: #e83c30;
    width: 100%;
    height: 18px;
    z-index: 1;
    align-self: flex-end;
    display: flex;
    /* justify-content: center; */
    color:#c0c0c0;
    background-color: transparent;
    height: 20px;
    margin-left: 10px;
    mix-blend-mode: difference;
}

.mouse{
    position:fixed;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /* background-color: rgb(229, 225, 210); */
    background-color: #c0c0c0;
    mix-blend-mode: difference;
    overflow-x: hidden;
    isolation: isolate;
    border: 0px;
    transition: background-color 0.5s ease, border 0.5s ease, height 0.5s ease, width 0.5s ease, transform 0.5s ease;  
    transform-origin: center;
    pointer-events: none;
    will-change: background-color, border, height, width, transform;
}

.fullPage{
    top:0;
    left:0;
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-color: #fff6dc;
}

.flexContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

#logoHomePage {
  top: 20px;
  position: absolute;
  left: calc(50vw - 30px);
  height: 60px;
  width: 60px;
}

.logoBack{
    position: absolute;
    top: 5px;

}

.button{
    color: black;
    display:flex;
    font-size: xx-large;
    align-items: center;
    text-align: right;
    font-family: 'Neue Haash Grotesk Display Pro';
    font-weight: bold;

}
.button:hover{
    color: #e83c30;
    cursor: none;
}
.button:active{
    scale: 0.9;
}


.buttonWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    text-align: center;
    font-size: x-large;
    font-family: 'Neue Haash Grotesk Display Pro';
    white-space: nowrap;
    position: absolute;   

}

.sep::after{
    content: 'REFESTUDIO';
}

/* .buttonWrapperBack{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    font-size: x-large;
    font-family: 'Neue Haash Grotesk Display Pro';
    white-space: nowrap;
    background-color: black;
    opacity: 0;
    position: absolute;   

} */

.firstAnimation{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: inherit;
    /*  --------- setup animazione ----------
    width: 100%;
    position: relative;
    left: 100%;
    animation: slide 20s linear infinite;
     */
    
    
}
.secondAnimation{
    width: 100%;
    position: relative;
    left: 100%;
    display: flex;
    flex-direction: row;
    /* animation: slide 20s linear infinite;
    animation-delay: 10s; */
}

#background{
    filter: contrast(0.8);
}


.hamburger{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
    width:60px;
    height: 60px;
}



.hamburger:active{
    scale: 0.9;
}

.hamburger > div {
    border: solid 0.2px white;
    width: 90%;
    height: 5%;
    background-color: white;
}


.hamburgerMenu{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: large;
    font-weight: bold;
    font-family: 'Neue Haash Grotesk Display Pro';
    color: black;
}

.hamburgerMenu > img{
    width: 150%;
    height: 150%;    
    object-fit: cover;
    position: absolute;
    top:-25%;
    left:-25%;
}

.hamburgerMenu > .button{
    font-size: large;
    margin: 30px 0px;
    position: relative;
}

@keyframes slide {
    0% {
      left:100%;
    }
    100% {
      left: -100%;
    }
  }

@media (max-width: 1420px) {
    .buttonWrapper {
        justify-content: center;
    }
}

@media (max-width: 1127px) {
    .buttonWrapper {
        justify-content: space-between;
    }
    .sep::after{
        content: 'REFE';
    }
}
@media (max-width: 990px) {
    .buttonWrapper {
        justify-content: center;
    }
    .sep::after{
        content: 'REFE';
        margin: 0 3px;
    }
}

@media (max-width: 890px){
    .buttonWrapper{
        display: none;
    }
    #logoHomePage{
        left: 20px;
    }
    .logoBack{
        left: 5px;
    }
    .mouse{
        display: none;
    }
    .fullPage *{
        cursor:auto ;
    }
}


