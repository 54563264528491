.webProjects {
  background-color: black;
  color: white;
  font-family: Inter, sans-serif;
  cursor: none;
}

.main-container {
  display: flex;
}

aside {
  display: flex;
  flex-direction: column;
  padding: 0.7rem;
  height: 100vh;
  box-sizing: border-box;
  position: absolute;
}

.aside-left {
  padding-top: 5.2rem;
  left:0;
}
.aside-right{
  right: 0;
}

.logo-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.7rem;
}

.ProjectAltContainer {
  flex: 1;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scroll-behavior: smooth;
  flex-direction: column;
  height: 100vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ProjectAltContainer::-webkit-scrollbar {
  display: none;
}


.logo {
  display: block;
  height: 3rem;
  z-index: 1;
}

.navLink {
  color: white;
  font-weight: 400;
  font-size: 0.95rem;
  writing-mode: vertical-lr;
}

.navLink-left {
  align-self: flex-start;
}
.navLink-left:hover{
  color: #e83c30;
}
.navLink-alt:hover{
  color: #e83c30;
}

.navLink-right {
  align-self: flex-end;
}
.navLink-right span:hover{
  color: #e83c30;
}

.navLink-left > span,
.navLink-alt > span {
  display: inline-block;
  margin-top: 0.7rem;
}

.navLink-right > span {
  display: inline-block;
  margin-bottom: 0.7rem;
}
.category-active{
  font-size: 1.5rem;
  color: #e83c30;
  pointer-events: none;
}

.navLink-alt {
  display: inline;
  position: absolute;
  bottom: 0.7rem;
}

#logoProjects {
  position: fixed;
  top: 0.7rem;
  left: 0.7rem;
  height: 3rem;
  width: 5rem;
}
.mouse{
  position:fixed;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* background-color: rgb(229, 225, 210); */
  background-color: #c0c0c0;
  mix-blend-mode: difference;
  overflow-x: hidden;
  isolation: isolate;
  transition: background-color 0.5s ease, border 0.5s ease, height 0.5s ease, width 0.5s ease, transform 0.5s ease; 
  transform-origin: center;
  pointer-events: none;
}

.logoBack{
  position: fixed;
  top:calc(0.7rem - 15px);
  left:calc(0.7rem - 15px);
}


@media (width >= 992px) {
  .aside-left {
    display: none;
  }
}

@media (width >= 1300px) {
  

  .navLink-left {
    transform: scale(-1);
  }

  .aside-left {
    display: block;
  }

  .navLink-alt {
    display: none;
  }
}



